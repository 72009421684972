$project-font-url: 'https://fonts.googleapis.com/css?family=Barlow:100,200,400,500,600,700,800';
@use "../poloVariables" as polo;
@use "../../vendor/oi-expandable/index" as oiExpandable;

.oi-expandable-wrapper .oi-expandable-wrapper-row .oi-expandable-item {
  @media (min-width: oiExpandable.$oi-expandable-breakpoint-md) {
    &.expanded {
      padding: 16px 32px;
    }
  }
  @media (min-width: oiExpandable.$oi-expandable-breakpoint-lg) {
    padding: 112px 54px;
    &.expanded {
      padding: 32px;
    }
  }
  @media (min-width: oiExpandable.$oi-expandable-breakpoint-xl) {
    &.expanded {
      padding: 112px 96px 68px 96px;
    }
  }

  // Title always white.
  .oi-expandable-title {
    color: polo.$white;
  }

  // Content style.
  .oi-expandable-content {
    .col {
      > *:not(:last-child) {
        margin-bottom: 8px;
      }

      img {
        width: 54px;
        height: 54px;
      }

      h6 {
        font-weight: 700;
      }

      p {
        font-weight: 500;
        text-align: left;
      }
    }
  }
}

.wb-top {
  margin-bottom: 20px;
  @media (min-width: 992px) {
    height: 240px;
  }
  @media (min-width: 1200px) {
    height: 180px;
  }
}
.wb-bottom {
  padding-left: 30px;
  position: relative;

  &:before {
    content: '>';
    color: polo.$secondary;
    font-family: Courier New, Courier, monospace;
    font-size: 24px;
    position: absolute;
    font-weight: 900;
    left: 0;
    top: 5px;
  }
}
